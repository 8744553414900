<template>
  <div class="accordion-wrapper">
    <div class="accordion-header"
         @click="setExpanded"
         :style="headerPositionStyles">
      <div class="accordion-title">
        {{title}}
      </div>
      <i class="icon">
        {{ expanded ? '&#xe677;' :  '&#xe676;' }}
      </i>
    </div>
    <Transition
      enter-active-class="enter-active"
      leave-active-class="leave-active"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave">
      <div v-if="expanded"
           class="accordion-content">
          <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<script>
import { Accordion } from '@nsftx/games-sdk-js';

export default {
  name: 'AccordionTerminal',
  extends: Accordion,
};
</script>

<style lang="scss" scoped>
.accordion-wrapper {
  font-family: "Roboto", sans-serif, "nfont";
  .accordion-header {
    width: 100%;
    height: 54px;
    background-color: var(--button);
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    color: var(--text-primary-1);
    font-size: 18px;
    padding: 0 16px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      background-color: var(--card-hover);
    }
    &:active {
      background-color: var(--button-active);
    }

    &.disabled {
      background-color: var(--button-disabled);
      color: var(--text-primary-3);
    }
    .accordion-title,
    .icon {
      user-select: none;
    }
  }
  .accordion-content {
    width: 100%;
    float: left;
    overflow: hidden;
  }

  i {
    font-style: normal;
  }
}

.enter-active,
.leave-active {
  overflow: hidden;
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  -webkit-transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  -o-transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
}
@media all and (min-width: 1880px) {
  .accordion-wrapper {
    .accordion-header {
      height: 72px;
      font-size: 20px;
    }
  }
}
</style>
